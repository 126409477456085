// used to rename _ named keys into @ named keys

export const keyRenamer = obj => {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      keyRenamer(obj[key])
    } else {
      if (key[0] === "_") {
        const value = obj[key]
        delete obj[key]
        const split = key.split("")
        split[0] = "@"
        obj[split.join("")] = value
      }
    }
  }
  return obj
}
