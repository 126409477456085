import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { keyRenamer } from "../../helperFunctions/keyRenamer"
/*
  Used to easily wrap everything in a wrapper div as well as including header + footer on all pages

@props: {
  children: React.ReactNode?
  metaTitle: string;
  metaDescription: string;
  slug: string;
}
*/

const Layout = ({ children, metaDescription, metaTitle, slug, schema }) => {
  return (
    <div className="font-body text-fontBlack bg-sand relative">
      <Header
        metaDescription={metaDescription}
        metaTitle={metaTitle}
        slug={slug}
      />
      <main>{children}</main>
      <Footer />
      {schema && (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(keyRenamer(schema.one)),
            }}
          ></script>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(keyRenamer(schema.two)),
            }}
          ></script>
        </>
      )}
    </div>
  )
}
export default Layout
