import React, { useRef } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { FaBars, FaTimes } from "react-icons/fa"

/*
Header component used through the site.
Takes in props regarding the metaTitle + description. So used in composition style

@props: {
  metaDescription: string;
  metaTitle: string;
  slug: string;
}

## Note that a metaDescription or title can include #YEAR# and it will be replaced with the current year.
*/

const Header = ({ metaDescription, metaTitle, slug }) => {
  const navRef = useRef(null)
  const burgerRef = useRef(null)
  const crossRef = useRef(null)
  const menuClick = () => {
    navRef.current.classList.toggle("hidden")
    navRef.current.classList.toggle("flex")
    burgerRef.current.classList.toggle("hidden")
    crossRef.current.classList.toggle("hidden")
  }
  if (metaTitle.includes("#YEAR#"))
    metaTitle = metaTitle.replace("#YEAR#", new Date().getFullYear())
  if (metaDescription.includes("#YEAR#"))
    metaDescription = metaDescription.replace(
      "#YEAR#",
      new Date().getFullYear()
    )
  return (
    <>
      <Helmet htmlAttributes={{ lang: "da" }}>
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {slug && <link rel="canonical" href={slug} />}
        <script type="text/javascript">
          {`
                (
                  function(w, c, t, u) {
                w._wct = w._wct || {};
                w._wct = u;
                var s = c.createElement(t);
                s.type = 'text/javascript'; s.async = true; s.src = 'https://goto-offer.com/wct.js';
                var r = c.getElementsByTagName(t)[0];
               r.parentNode.insertBefore(s, r);
              }(window, document, 'script', {
              'uid' : 'a19ua2',
              'google_tracking_id' : 'UA-59947918-16'
              }))
          `}
        </script>
        <script type="text/javascript">
          {`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '2290106871122248');
           fbq('track', 'PageView');
          `}
        </script>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=2290106871122248&ev=PageView&noscript=1"
          />`,
          }}
        ></noscript>

        <script id="sleeknoteScript" type="text/javascript">
          {`
          (function () {
            var sleeknoteScriptTag = document.createElement("script");
            sleeknoteScriptTag.type = "text/javascript";
            sleeknoteScriptTag.charset = "utf-8";
            sleeknoteScriptTag.src = ("//sleeknotecustomerscripts.sleeknote.com/62833.js");
            var s = document.getElementById("sleeknoteScript");
            s.parentNode.insertBefore(sleeknoteScriptTag, s);
            })();
          `}
        </script>

      </Helmet>

      <header className="flex justify-between h-20 items-center container relative">
        <Link to="/" className="font-logo text-2xl">
          Opholdsguide
        </Link>
        <nav className="nav hidden z-30" ref={navRef}>
          <Link to="/" activeClassName="navActive" className="mr-10">
            Alle ophold
          </Link>
          <Link to="/blog/" activeClassName="navActive">
            Blog
          </Link>
        </nav>
        <div className="flex justify-center items-center md:hidden z-50 cursor-pointer">
          <button
            className="fas fa-bars text-lg"
            ref={burgerRef}
            onClick={menuClick}
          >
            <FaBars />
          </button>

          <button
            className="fas fa-times text-lg hidden text-white"
            ref={crossRef}
            onClick={menuClick}
          >
            <FaTimes />
          </button>
        </div>
      </header>
    </>
  )
}

export default Header
