import React from "react"
import { Link } from "gatsby"
import { FaAngleRight } from "react-icons/fa"

/*
  Footer component used throughout the page
*/

const Footer = () => {
  return (
    <footer className="bg-footerBlack py-xl text-white">
      <div className="container">
        <section className="border-b-2 border-white grid grid-cols-1 md:grid-cols-3 gap-xl pb-xl">
          <article>
            <h3 className="font-logo">Opholdsguide</h3>
            <p>
              Priser svinger efter sæson og opdateres med jævne mellemrum. Husk
              det altid er prisen på vores samarbejdspartneres hjemmeside, som
              er den gældende.
            </p>
            <p className="my-sm font-semibold">
              Opholdsguide.dk - {new Date().getFullYear()}
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <a
                href="https://www.facebook.com/Opholdsguidedk-504025013407372"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Facebook
              </a>
            </p>
          </article>

          <article>
            <h3>Information</h3>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/information/bliv-annoncoer/" className="footer-link">
                Bliv annoncør
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link
                to="/information/cookie-og-privatlivspolitik/"
                className="footer-link"
              >
                Cookie- og privatlivspolitik
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/information/kontakt/" className="footer-link">
                Kontakt
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/information/om/" className="footer-link">
                Om os
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link
                to="/information/rangering-af-hoteller/"
                className="footer-link"
              >
                Rangering af ophold/hoteller
              </Link>
            </p>
          </article>

          <article>
            <h3>Populære ophold</h3>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/badehoteller/" className="footer-link">
                Badehoteller
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/gourmetophold/" className="footer-link">
                Gourmetophold
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/kroophold/" className="footer-link">
                Kroophold
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/slotsophold/" className="footer-link">
                Slotsophold
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/weekendophold/" className="footer-link">
                Weekendophold
              </Link>
            </p>
            <p className="flex mb-2 items-center">
              <FaAngleRight className="mr-1" />
              <Link to="/wellness-spaophold/" className="footer-link">
                Wellness- og spaophold
              </Link>
            </p>
          </article>
        </section>
      </div>
    </footer>
  )
}

export default Footer
